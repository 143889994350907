import useSWR, { SWRConfiguration } from "swr";
import request from "@/utils/request";

export const disableRevalidate = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: 0,
};

export default function useRequest<Response>(
  options: {
    function: string;
    data: Object;
    header?: Object;
  } | null,
  swrOptions?: SWRConfiguration
) {
  // @ts-ignore
  return useSWR<Response>(
    options || null,
    (options) => {
      return request.rpc(
        options.function,
        options.data,
        // @ts-ignore
        options.header
      );
    },
    swrOptions
  );
}
